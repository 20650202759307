.infinite-scroll-parent {
    max-height: 75vh;
    overflow: scroll;
}

.modal-business-booking {
    overflow-y: hidden !important;
}

.modal-business-booking .modal-body{
    height: 82vh;
}