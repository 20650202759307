th {
    height: 48px;
    background-color: rgba(0, 0, 0, 0.03) !important;
    border-top: none !important;
}

tr.details-row {
    background-color: var(--gray-100) !important;
    border: 1px solid rgb(222, 226, 230);
}

.btn-primary {
    color: white !important;
    background-color: #284D73 !important;
    border-color: #284D73 !important;
    border-radius: 0px !important;
    height: 38px !important;
}

.input-primary{
    height: 38px !important;
    margin-right: 10px;
}

.info-bar {
    width: 100%;
    border-bottom: 1px solid var(--gray-300);
    border-top: 1px solid var(--gray-300);
}

.table thead th {
    border-bottom-width: 1px;
    background-color: var(--gray-100);
}

th, td {
    vertical-align: middle !important;
}

.text-light {
    color: var(--gray-500) !important;
}

/* Disabled ACE editor */
.ace-editor-disabled {
    background-color: #ececec !important;
}

.body-col{
    margin: 20px 0;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.month-count-title{
    text-align: right;
}

.view-change{
    background-color: transparent !important;
    color: #284D73 !important;
    border: none !important;
    border-right: 1px solid #284D73 !important;
}
.view-change:focus{
    box-shadow: none !important;
}

input:focus, button:focus {
    outline: none !important;
}