.booking-row{
    background-color: var(--gray-100);
    cursor: default;
}

.booking-row th, .booking-row td {
    vertical-align: top !important;
}

.booking-row td{
    cursor: default !important;
}