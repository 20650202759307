.modal-business-booking .modal-content{

    border-radius: 0px;
}

.modal-business-booking .modal-dialog{
    width: 90%;
    max-width: none;
}

.modal-business-booking .card{
    margin-bottom: 0px !important;
}

.modal-backdrop.show{
    opacity: 0.8 !important;
}

.provider-name{
    padding-left: 15px !important;
}

.service-group-icon{
    margin-right: 10px !important;
}