:root {
    --white:    #fff;
    --gray-100: #f8f9fa;
    --gray-200: #ebebeb;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #999;
    --gray-700: #444;
    --gray-800: #303030;
    --gray-900: #222;
    --black:    #000;

    --blue:    #375a7f;
    --indigo:  #6610f2;
    --purple:  #6f42c1;
    --pink:    #e83e8c;
    --red:     #B22567;
    --red-trans:     #B22567CC;
    --orange:  #E6710A;
    --yellow:  #FFDC7B;
    --green:   #8AB31D;
    --teal:    #20c997;
    --cyan:    #3498DB;

    --primary:       var(--orange);
    --secondary:     var(--gray-700);
    --success:       var(--orange);
    --info:          var(--cyan);
    --warning:       var(--yellow);
    --danger:        var(--red);
    --light:         var(--gray-800);
    --dark:          var(--gray-500);

    --yiq-contrasted-threshold:  175;

    /* Body */

    --body-bg:                   var(--gray-900);
    --body-color:                var(--white);

    /* Links */

    --link-color:                var(--success);

    /* Fonts */

    --font-family-sans-serif:      "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    /* Tables */

    --table-accent-bg:             var(--gray-800);

    --table-border-color:          var(--gray-700);

    --table-dark-bg:               var(--gray-500);
    --table-dark-border-color:     darken(var(--gray-500), 7.5%);
    --table-dark-color:            var(--body-bg);

    /* Forms */

    --input-border-color:                transparent;

    --input-group-addon-color:           var(--gray-500);
    --input-group-addon-bg:              var(--gray-700);

    --custom-file-color:                 var(--gray-500);
    --custom-file-border-color:          var(--gray-700);

    /* Dropdowns */

    --dropdown-bg:                       var(--gray-900);
    --dropdown-border-color:             var(--gray-700);
    --dropdown-divider-bg:               var(--gray-700);

    --dropdown-link-color:               var(--white);
    --dropdown-link-hover-color:         var(--white);
    --dropdown-link-hover-bg:            var(--primary);

    /* Navs */

    --nav-link-disabled-color:           var(--gray-500);

    --nav-tabs-border-color:             var(--gray-700);
    --nav-tabs-link-hover-border-color:  var(--nav-tabs-border-color) var(--nav-tabs-border-color) transparent;
    --nav-tabs-link-active-color:        var(--white);
    --nav-tabs-link-active-border-color: var(--nav-tabs-border-color) var(--nav-tabs-border-color) transparent;

    /* Navbar */

    --navbar-padding-y:                  1rem;

    --navbar-dark-color:                 var(--white);
    --navbar-dark-hover-color:           var(--success);

    --navbar-light-color:                rgba(--white,.5);
    --navbar-light-hover-color:          var(--white);
    --navbar-light-active-color:         var(--white);
    --navbar-light-disabled-color:       rgba(--white,.3);
    --navbar-light-toggler-border-color: rgba(--white,.1);

    /* Pagination */

    --pagination-color:                  var(--white);
    --pagination-bg:                     var(--success);
    --pagination-border-width:           0;
    --pagination-border-color:           transparent;

    --pagination-hover-color:            var(--white);
    --pagination-hover-bg:               lighten(var(--success), 10%);
    --pagination-hover-border-color:     transparent;

    --pagination-active-bg:              var(--pagination-hover-bg);
    --pagination-active-border-color:    transparent;

    --pagination-disabled-color:         var(--white);
    --pagination-disabled-bg:            darken(var(--success), 15%);
    --pagination-disabled-border-color:  transparent;

    /* Jumbotron */

    --jumbotron-bg:                      var(--gray-800);

    /* Cards */

    --card-cap-bg:                       var(--gray-700);
    --card-bg:                           var(--gray-800);

    /* Popovers */

    --popover-bg:                        var(--gray-800);

    --popover-header-bg:                 var(--gray-700);

    /* Modals */

    --modal-content-bg:                  var(--gray-800);
    --modal-content-border-color:        var(--gray-700);

    --modal-header-border-color:         var(--gray-700);

    /* Progress bars */

    --progress-bg:                       var(--gray-700);

    /* List group */

    --list-group-bg:                     var(--gray-800);
    --list-group-border-color:           var(--gray-700);

    --list-group-hover-bg:               var(--gray-700);

    /* Breadcrumbs */

    --breadcrumb-bg:                     var(--gray-700);

    /* Close */

    --close-color:                       var(--white);
    --close-text-shadow:                 none;

    /* Code */

    --pre-color:                         inherit;
}