div.collapse.in {
    display: table-row;
}

.category-header{
    height: 55px;
    vertical-align: middle;
}

.category-header-error{
    background-color: var(--red);
    color: white;
}
.category-result-table-header {
    cursor: default !important;
}

.table-hover tbody tr:hover,.category-header-error:hover  {
    color: #212529 !important;
    background-color: rgba(0,0,0,.075) !important;
}