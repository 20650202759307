.modal-business-booking .modal-content{

    border-radius: 0px;
}

.modal-business-booking .modal-dialog{
    width: 90%;
    max-width: none;
}

.modal-business-booking .card{
    margin-bottom: 0px !important;
}

.modal-backdrop.show{
    opacity: 0.8 !important;
}

.service-group-header{
    background-color: var(--gray-100);
}

.service-group-name{
    padding-left: 50px !important;
}