.main-table > tbody > tr > td {
    vertical-align: middle;
    cursor: pointer;
    padding: 0.5rem;
}

.tr-details {
    padding: 1rem !important;
    cursor: default !important;
}

@media (min-width: 768px) {
    .table-responsive {
        overflow: visible;
    }
}