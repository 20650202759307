.category-search-col .form-group{
    width: 95%;
}

.category-search-button-col{
    padding-left: 0px !important;
}

.form-horizontal .control-label {
    margin-bottom: 5px !important;
}