body {
  hyphens: auto;
  /*Fix for IE*/
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-chars: auto 3;
  -webkit-hyphenate-limit-lines: 4;
  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: auto 3;
  -ms-hyphenate-limit-lines: 4;
}

html * {
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
}

.container {
  width: 100% !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 20px !important;
  border-radius: 0px;
}

.form-control {
  display: block !important;
  width: 100% !important;
  height: 38px !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important; 
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.navbar{
  padding: 1rem;
}
