.modal-footer{
    border: none !important;
}

.modal-header{
    border: none !important;
}

.modal-business-booking .modal-body{
    padding-bottom: 0px;
}

.modal-business-booking .modal-header{
    padding-bottom: 0px;
}

.modal-business-booking .modal-footer{
    padding-right: 31px;
    padding-top: 0px;
}

.load-csv {
    cursor: pointer;
}